@mixin xxl { @media ($xxl) { @content; } }
@mixin xl { @media ($xl) { @content; } }
@mixin lg { @media ($lg) { @content; } }
@mixin md { @media ($md) { @content; } }
@mixin sm { @media ($sm) { @content; } }
@mixin xs { @media ($xs) { @content; } }


@mixin webfont($fontName, $localName1, $localName2, $fileName, $fontWeight: normal, $fontStyle: normal) {
    @font-face
        font-family: $fontName;
        src: local($localName1), local($localName2),
            url('../fonts/'+$fileName+'.woff2') format('woff2'),
            url('../fonts/'+$fileName+'.woff') format('woff');
        font-weight: $fontWeight;
        font-style: $fontStyle;
}


@mixin textOverflow($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin rem($value) {
    font-size: ($value/$font-size)*1rem;
}


@mixin transit($duration: 0.25s) {
    transition: all $duration ease-out;
}


@mixin hidden() {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    @include transit();
}

@mixin visible() {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}

@mixin slideup() {
    @include hidden();
    max-height: 0;
    @include transit();
}

@mixin slidedown() {
    @include visible();
    max-height: none;
}

@mixin pseudo() {
    content: '';
    display: block;
    position: absolute;
}

@mixin bg($position,$size) {
    background-repeat: no-repeat;
    background-position: $position;
    background-size: $size;
}

@mixin centered-img() {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50);
}