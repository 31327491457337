/* ──────────────────────────────────────────────────────────────────
 * ── BUTTON
 * ─────────────────────────────────────────────────────────────── */

.btn {
    background-color: $color-one;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    height: 58px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    padding: 1px 30px 0;
    border-radius: 50px;
    @include transit();
    @include md {
        height: 48px;
    }
    @include sm {
        line-height: 1.4;
        font-size: 12px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.btn-dark {
    @extend .btn;
    background-color: #36393a;

    &:hover {
        background-color: darken(#36393a,15%);
        opacity: 1;
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── TITLE
 * ─────────────────────────────────────────────────────────────── */

.title {
    font-size: 34px;
    font-weight: 800;
    line-height: (40/34);
    @include md {
        font-size: 30px;
    }

    i {
        color: $color-one;
        font-style: normal;
    }
}

.title-light {
    @extend .title;
    color: #fff;
}

.title-lg {
    @extend .title;
    font-size: 48px;
    @include md {
        font-size: 38px;
    }
    @include sm {
        font-size: 30px;
    }
}

.title-sm {
    font-size: 28px;
    font-weight: bold;
}


/* ──────────────────────────────────────────────────────────────────
 * ── SECTIONS
 * ─────────────────────────────────────────────────────────────── */

.section {
    padding: 90px 0 0;
    @include md {
        padding: 45px 0;
    }
}

.section-colored {
    background-color: #f6f7f9;
}

.txt {
    font-size: 17px;
    line-height: (22/17);
}


/* ──────────────────────────────────────────────────────────────────
 * ── FLAG
 * ─────────────────────────────────────────────────────────────── */

.flag {
    width: 80px;
    height: 95px;
    @include bg(bottom center, 100% auto);
    background-image: url($path+'icons/flag-dark.svg');
    @include sm {
        width: 60px;
        height: 70px;
    }

    .ico {
        fill: #fff;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-70%);
        @include sm {
            width: 30px;
            height: 30px;
        }
    }

    &--red {
        background-image: url($path+'icons/flag-red.svg');
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── INPUT
 * ─────────────────────────────────────────────────────────────── */
.form-group {
    margin-bottom: 27px;
    position: relative;

    .help-block {
        position: absolute;
        bottom: 2px;
        right: 2px;
        font-size: 0.7em;
    }
}

.form {
    position: relative;

    &.success {
        .form-success {
            @include visible();
        }
    }

}

.form-success {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 1.6;
    z-index: 10;
    color: $color-main;
    @include hidden();
}

.input {
    height: 58px;
    border-radius: 3px;
    background-color: #fff;
    text-align: center;
    font-size: 14px;
    @include sm {
        height: 48px;
    }

    &::placeholder {
        color: #8c8d8e;
    }
}

.input-dark {
    @extend .input;
    background-color: #f6f7f9;
}


.cbox {
    position: relative;

    &__in {
        display: flex;
        align-items: center;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
    }

    &__ctrl {
        width: 20px;
        height: 20px;
        flex: none;
        border-radius: 3px;
        border: 1px solid #c9c9ca;
        background-color: #f6f7f9;
        margin-right: 10px;
        position: relative;

        &:before {
            @include pseudo();
            width: 10px;
            height: 10px;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: #36393a;
            @include hidden();
        }
    }

    &__label {
        font-size: 11px;
        color: #8c8d8e;
    }

    &__input:checked ~ &__in &__ctrl:before {
        @include visible();
    }

    .help-block {
        flex: none;
        width: 100%;
        position: relative;
        bottom: auto;
        right: auto;
        text-align: right;
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── SLICK
 * ─────────────────────────────────────────────────────────────── */

.slick-slide {
    outline: none;
}

.slick-arrow {
    display: block;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: rgba(#d1d2d2,0.5);
    @include bg(center,10px 16px);
    background-image: url($path+'icons/chevron-right.svg');
    cursor: pointer;
    @include transit();

    &:hover {
        background-color: #d1d2d2;
    }

    &.prev {
        transform: rotateY(180deg);
    }

    &.next {
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── THANKS
 * ─────────────────────────────────────────────────────────────── */

.thanks {
    max-width: 700px;
    margin: 100px auto;
    box-shadow: 0 0 15px 0 rgba(#000,0.5);
    padding: 50px 30px;
    text-align: center;
    line-height: 1.6;
    @include md {
        margin: 50px auto;
    }
    @include sm {
        margin: 25px auto;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__ico {
        width: 100px;
        height: 100px;
        fill: $color-one;
        margin-bottom: 50px;
    }
}