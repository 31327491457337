//- Colors
$color-main: #191c1d;
$color-one: #e96559;

$color-error: #b71c1c;

//- Base
$font-size: 16px;
$font-base: 'Montserrat', 'Arial', 'Helvetica', sans-serif;

$path: '../img/';

//- Breakpoints
$xs: 'max-width: 543px';
$sm: 'max-width: 767px';
$md: 'max-width: 991px';
$lg: 'max-width: 1199px';
$xl: 'max-width: 1399px';