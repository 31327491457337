@mixin has($element) {
  &__#{$element} {
    @content;
  }
}

@mixin variant($modifier) {
  &--#{$modifier} {
    @content;
  }
}