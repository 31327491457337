/* ──────────────────────────────────────────────────────────────────
 * ── HEADER
 * ─────────────────────────────────────────────────────────────── */

.header {
    background-color: #fff;
    position: relative;
    padding-top: 9px;
    min-height: 130px;
    @include md {
        min-height: 67px;
    }

    &:before {
        @include pseudo();
        height: 9px;
        width: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(to right, #E96559 0%, #f58b41 50%, #E96559 100%);
    }

    &__wrapper {
        background-color: #fff;
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.57);
    }

    &__in {
        min-height: 130px;
        @include transit();
        @include md {
            min-height: auto;
            padding: 10px 0;
        }
    }

    &__right {
        text-align: right;
    }
}

.headroom {
    width: 100%;
    @include transit();

    &--not-top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.57);

        .header__in {
            min-height: auto;
            padding: 5px 0;
        }
    }
}


.h-logo {
    max-width: 236px;
    @include md {
        max-width: 150px;
    }

    img {
        max-width: 100%;
    }
}


.header-contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include sm {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 2em;
      @include sm {
        margin-right: 0;
        margin-bottom: 0.5em;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: (18em/16);
    line-height: 1;
    @include md {
      font-size: 0.8em;
    }

    & > span {
      display: flex;
      align-items: center;
      line-height: 1;

      span {
        display: inline-block;
        font-size: (30em/18);
        font-style: normal;
        margin-left: 0.3em;
        @include sm {
          font-size: (22em/18);
        }
      }
    }

    .ico {
      width: 18px;
      height: 18px;
      fill: $color-one;
      margin-right: 0.5em;
      @include md {
        width: 16px;
        height: 16px;
      }
    }

    &:hover {
      color: $color-one;
    }
  }
}

// .h-phone {
//     font-size: 18px;
//     color: $color-main;
//     display: inline-flex;
//     align-items: center;
//     @include transit();
//     @include md {
//         font-size: 14px;
//         margin-bottom: 10px;
//     }
//
//     i {
//         display: inline-block;
//         margin-left: 9px;
//         font-size: 30px;
//         font-style: normal;
//         @include md {
//             font-size: 20px;
//         }
//         @include sm {
//             font-size: 18px;
//         }
//     }
//
//     &:hover {
//         opacity: 0.8;
//     }
// }
//
// .h-btn {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     height: 45px;
//     background-color: $color-one;
//     color: #fff;
//     text-align: center;
//     text-transform: uppercase;
//     font-size: 14px;
//     font-weight: bold;
//     border-radius: 50px;
//     padding: 1px 10px 0;
//     min-width: 196px;
//     line-height: 1;
//     margin-left: 40px;
//     @include transit();
//     @include md {
//         height: 35px;
//         min-width: auto;
//         margin-left: 15px;
//     }
//     @include sm {
//         margin-left: 0;
//         font-size: 12px;
//         min-width: 146px;
//     }
//
//     &:hover {
//         opacity: 0.8;
//     }
// }
