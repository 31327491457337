/*
 * ─── START ───────────────────────────────────────────────────────────
*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body, html {
    padding: 0;
    border: 0;
    vertical-align: baseline;
    width: 100%;
    overflow-x: hidden;
    min-height: 100%;
}

html {
  font-size: $font-size;
}

body {
    font-family: $font-base;
    color: $color-main;
    font-weight: normal;
    background-color: #fff;
    line-height: normal;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}


/*
 * ─── BROWSEHAPPY ───────────────────────────────────────────────────────────
*/

.browsehappy {
    display: block;
    margin: 0;
    padding: 30px;
    text-align: center;
    background: $color-error;
    color: #fff;
    font-size: 18px;
    line-height: 1.4;

    a,
    a:hover {
        color: #fff;
        text-decoration: underline;
    }
}


/*
 * ─── FOOTER TO BOTTOM ───────────────────────────────────────────────────────────
*/
.main {
  flex-grow: 1;
}


/*
 * ─── LINKS ───────────────────────────────────────────────────────────
*/

a {
    color: inherit;
    text-decoration: none;
    @include transit();

    &:hover {
        color: color-base;
        text-decoration: none;
        border-bottom-color: color-base;
    }
}


/*
 * ─── BASIC ───────────────────────────────────────────────────────────
*/

a,
button,
input,
textarea {
    outline: none;
    @include transit();

    &:focus {
        outline: none;
    }
}

ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

button {
    display: inline-block;
    border: 0;
    background: transparent;
    font-family: inherit;
}

button, button[type="submit"],
input[type="submit"] {
    cursor: pointer;
}

input, textarea {
    display: block;
    width: 100%;
    line-height: normal;
    border: 0;
    background-color: transparent;
    font-family: inherit;
}

input[type="search"] {
    -webkit-appearance: none;
}

textarea {
    width: 100%;
    resize: none;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
    font-weight: inherit;
}

.hide {
    display: none;
}

.hidden {
    opacity: 0;
    visibility: 0;
}

.iframe {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.help-block {
    color: $color-error;
    font-size: 0.8rem;
    @media ($md) {
        font-size: 0.7rem;
    }
}

[data-tab-content] {
    @nclude hidden();
    max-height: 0;

    &.active {
        @include visible();
        max-height: none;
    }
}


.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.table {
  overflow-x: auto;
}

/*
 * ─── PLUGINS ───────────────────────────────────────────────────────────
*/

// Fancybox
.fancybox-close-small {
  display: none;
}

.fancybox-container {
  font-family: inherit;
}