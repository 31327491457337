/* ──────────────────────────────────────────────────────────────────
 * ── FOOTER
 * ─────────────────────────────────────────────────────────────── */

.footer {
    background-color: #f6f7f9;

    &__in {
        height: 57px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.f-copyright {
    font-size: 12px;
    color: #a3a4a5;
}

.f-socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__item {

        &:not(:first-child) {
            margin-left: 30px;
            @include sm {
                margin-left: 15px;
            }
        }

        .ico {
            fill: #36393a;
            @include transit();

            &:hover {
                opacity: 0.6;
            }

            &.fb {
                width: 8px;
                height: 16px;
            }

            &.vk {
                width: 21px;
                height: 12px;
            }

            &.insta {
                width: 18px;
                height: 18px;
            }
        }
    }
}


.modal {
    background-color: #fff;
    position: relative;
    padding: 50px 30px 25px;
    width: 556px;
    @include sm {
        padding: 50px 15px 20px;
        width: 90%;
    }

    &__close {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f6f7f9;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ico {
            width: 12px;
            height: 12px;
            fill: #8c8d8e
        }
    }

    &__title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
        @include sm {
            font-size: 18px;
        }
    }

    &__txt {
        font-size: 15px;
        color: #8c8d8e;
        line-height: (22/15);
        text-align: center;
        margin-bottom: 90px;
        @include sm {
            font-size: 12px;
            margin-bottom: 30px;
        }
    }

    &__form {
        max-width: 75%;
        margin: 0 auto;
        @include sm {
            max-width: 100%;
        }

        .form-group {
            margin-bottom: 16px;
        }

        &_ctrl {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .btn {
            width: 100%;
        }
    }
}