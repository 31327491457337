/* ──────────────────────────────────────────────────────────────────
 * ── PAGE: INDEX
 * ─────────────────────────────────────────────────────────────── */


/* ──────────────────────────────────────────────────────────────────
 * ── HERO
 * ─────────────────────────────────────────────────────────────── */

.hero {
    @include bg(center,cover);
    background-image: url($path+'bg-hero.jpg');
    @include sm {
        @include bg(left center,cover);
    }

    &__in {
        min-height: 583px;
        @include sm {
            min-height: 420px;
            text-align: center;
        }
        @include xs {
            min-height: 440px;
        }
    }

    &__body {
        color: #fff;
        margin-top: 85px;
        @include sm {
            margin-top: 45px;
        }
    }

    &__title {
        font-size: 46px;
        font-weight: 800;
        margin-bottom: 20px;
        @include sm {
            font-size: 25px;
        }
    }

    &__subtitle {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: (100em/1000);
        margin-bottom: 32px;

        i {
            font-size: 25px;
            font-weight: 800;
            color: $color-one;
            font-style: normal;
            text-transform: uppercase;
            margin: 0 4px;
            @include sm {
                font-size: 16px;
            }
        }
    }

    &__txt {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 30px;
        max-width: 65%;
        @include lg {
            max-width: 40%;
        }
        @include sm {
            font-size: 12px;
            max-width: 100%;
            line-height: 1.6;
        }
    }

    &__btn {
        min-width: 358px;
        @include sm {
            min-width: auto;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── ADVANTAGE
 * ─────────────────────────────────────────────────────────────── */

.advantage {
    overflow-x: hidden;
    margin-top: -65px;
    margin-bottom: 78px;
    @include md {
        margin-bottom: 30px;
    }

    &__in {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        padding-bottom: 30px;
    }

    &__item {
        width: (100%/4);
        padding: 0 20px;
        min-height: 120px;
        @include md {
            width: 50%;
            margin-bottom: 15px;
        }
        @include xs {
            width: 50%;
            min-height: 100px;
            padding: 0 10px;
        }
        @media (max-width: 400px) {
            width: 100%;
        }
    }

    &__body {
        background-color: #f6f7f9;
        box-shadow: 0px 3px 21px 0px rgba(140, 145, 155, 0.35);
        border-radius: 8px;
        padding: 10px 105px 10px 5px;
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        @include lg {
            padding-right: 95px;
        }
        @include sm {
            padding-right: 76px;
        }
    }

    &__txt {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        @include lg {
            font-size: 12px;
            padding: 0 10px;
        }
        @include sm {
            font-size: 10px;
        }
        @media (max-width: 400px) {
            font-size: 14px;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 26px;
        z-index: 1;
        @include lg {
            right: 15px;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── GOODS
 * ─────────────────────────────────────────────────────────────── */

.goods {

    &__title {
        margin-bottom: 70px;
        @include md {
            margin-bottom: 35px;
        }
    }

    &__row {
        @include md {
            justify-content: center;
        }
        @include sm {
            margin: 0 -10px;
        }
    }

    &__col {
        padding-bottom: 78px;
        @include md {
            padding-bottom: 35px;
        }
        @include sm {
            padding: 0 10px 35px;
        }
    }

    &__item {
        display: block;
        @include xs {
            max-width: 280px;
            margin: 0 auto;
        }
    }

    &__pic {
        height: 150px;
        @include bg(center,cover);
        margin-bottom: 25px;
        @include md {
            margin-bottom: 15px;
        }
        @include sm {
            height: 150px;
        }
    }

    &__name {
        font-size: 17px;
        font-weight: 600;
        line-height: (22/17);
        @include md {
            font-size: 14px;
        }
        @include xs {
            font-size: 10px;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── BENEFIT
 * ─────────────────────────────────────────────────────────────── */

.benefit {
    padding: 100px 0 0;
    @include md {
        padding: 60px 0 0;
    }

    &__in {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -17px;
        padding: 100px 0 120px;
        @include md {
            padding: 50px 0;
        }
    }

    &__item {
        padding: 0 17px;
        width: (100%/3);
        padding-bottom: 33px;
        @include md {
            width: 50%;
        }
        @include sm {
            width: 100%;
            padding-bottom: 15px;
        }
    }

    &__body {
        min-height: 120px;
        background-color: #fff;
        display: flex;
        align-items: center;
        box-shadow: 0px 3px 21px 0px rgba(140, 145, 155, 0.15);
        border-radius: 8px;
        padding: 15px 15px 25px 116px;
        position: relative;

        &:before {
            @include pseudo();
            width: 54px;
            height: 54px;
            background-color: #ededed;
            border-radius: 50%;
            top: 20px;
            left: 20px;
        }

        &:after {
            @include pseudo();
            width: 34px;
            height: 4px;
            background-color: $color-one;
            top: 20px;
            right: 0;
        }

        @include xs {
            min-height: 100px;
        }
    }

    &__txt {
        font-size: 17px;
        font-weight: 600;
        line-height: (22/17);
        @include md {
            font-size: 14px;
        }

        i {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
        }
    }

    &__icon {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);

        .ico {
            width: 68px;
            height: 69px;
            fill: #36393a;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── CALC
 * ─────────────────────────────────────────────────────────────── */

.calc {
    @include bg(center,cover);
    background-image: url($path+'bg-calc.jpg');

    &__in {
        position: relative;

        padding: 140px 0 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        @include md {
            text-align: center;
            padding-left: 30px;
            padding-right: 30px;
        }
        @include sm {
            padding: 50px 0;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        @include sm {
            display: none;
        }
    }

    &__title {
        margin-bottom: 40px;
        @include sm {
            text-align: center;
        }
    }

    &__txt {
        font-size: 17px;
        line-height: (26/17);
        margin-bottom: 90px;
        @include sm {
            margin-bottom: 30px;
            text-align: center;
        }
    }

    &__btn {
        min-width: 352px;
        @include lg {
            min-width: auto;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── TECH
 * ─────────────────────────────────────────────────────────────── */

.tech {
    padding: 100px 0 50px;
    @include md {
        padding: 50px 0 25px;
    }

    &__title {
        @include md {
            margin-bottom: 30px;
        }
    }

    &__body {
        margin-bottom: 45px;
    }

    &__txt {
        font-size: 17px;
        line-height: (22/17);
        color: #8c8d8e;

        p + p {
            margin-top: 1.5em;
        }
    }

    &__img {
        height: 325px;
        @include bg(center,contain);
        background-image: url($path+'printer.png');
        @include sm {
            height: 225px;
        }
        @include xs {
            display: none;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── SPECIAL
 * ─────────────────────────────────────────────────────────────── */

.special {
    position: relative;
    background-image: linear-gradient(to bottom, #FFFFFF 0%, #F6F7F9 100%);

    &:before {
        @include pseudo();
        @include bg(center,cover);
        background-image: url($path+'bg-special.jpg');
        top: 40px;
        bottom: 40px;
        left: 0;
        width: 100%;
        z-index: 0;
    }

    &__body {
        max-width: 674px;
        margin: 0 auto;
        padding: 40px 80px 50px;
        @include bg(center,cover);
        background-image: url($path+'bg-special-2.jpg');
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 1;
        @include sm {
            padding: 40px 30px;
        }
        @include xs {
            padding: 20px 30px;
        }
    }

    &__top {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: (300em/1000);
        font-weight: 800;
        @include bg(top left,contain);
        background-image: url($path+'icons/special-frame.svg');
        height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fcbe5d;
        max-width: 400px;
        margin: 0 auto 30px;
        text-align: center;
        @include xs {
            font-size: 10px;
            letter-spacing: (100em/1000);
            background-size: 100% 100%;
            margin-bottom: 15px;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 15px;
        @include xs {
            font-size: 20px;
        }
    }

    &__txt {
        font-size: 15px;
        line-height: (22/15);
        margin-bottom: 40px;
        @include xs {
            font-size: 12px;
            margin-bottom: 20px;
        }
    }

    &__form {

        &_group {
            max-width: 358px;
            margin: 0 auto 25px;
        }

        &_btn {
            min-width: 394px;
            @include sm {
                min-width: 100%;
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── MATERIAL
 * ─────────────────────────────────────────────────────────────── */

.material {
    padding-bottom: 22px;
    @include md {
        padding-bottom: 0;
    }
    @include xs {
        padding-bottom: 30px;
    }

    &__in {
        position: relative;
    }

    &__left {
        padding: 130px 0 0;
        @include md {
            padding-top: 60px;
        }
    }

    &__right {
        padding-top: 50px;
        @include sm {
            padding-top: 0;
            padding-bottom: 30px;
        }
    }

    &__title {
        margin-bottom: 45px;
    }

    &__txt {
        font-size: 17px;
        line-height: (22/17);
        color: #8c8d8e;
        margin-bottom: 115px;
        @include md {
            margin-bottom: 50px;
        }
    }

    &__subtitle {
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 40px;
    }

    &__row {
        @include md {
            justify-content: center;
        }
        @include sm {
            margin: 0 -10px;

            .col {
                padding: 0 10px;
            }
        }
    }

    &__item {
        max-width: 238px;
        margin: 0 auto;
        padding-bottom: 23px;
    }

    &__pic {
        height: 138px;
        @include bg(center,cover);
        margin-bottom: 16px;
    }

    &__name {
        font-weight: 600;
        @include md {
            font-size: 14px;
        }
        @include xs {
            font-size: 10px;
        }
    }

    &__box {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        padding: 50px 120px 47px 0;
        border-radius: 0 12px 12px 0;
        width: 39%;
        @include lg {
            width: 45%;
        }
        @include md {
            position: relative;
            bottom: auto;
            left: auto;
            width: 100%;
            margin: 0 auto;
        }
        @include sm {
            max-width: 100%;
            padding: 25px 25px 25px 0;
            text-align: center;
        }
        @include xs {
            padding: 55px 25px 25px 10px;
        }

        &:before {
            @include pseudo();
            top: 0;
            right: 0;
            width: 1000px;
            background-color: #fff;
            height: 100%;
            border-radius: 0 12px 12px 0;
            z-index: 0;
        }

        &_body {
            position: relative;
            z-index: 1;
        }

        &_icon {
            position: absolute;
            top: 0;
            right: 36px;
            @include sm {
                display: none;
            }
        }

        &_title {
            font-size: 24px;
            line-height: (32/24);
            margin-bottom: 33px;
            @include xs {
                font-size: 20px;
                margin-bottom: 15px;
            }

            strong {
                font-weight: 800;
                text-transform: uppercase;
                letter-spacing: (50em/1000);
            }
        }

        &_txt {
            color: #8c8d8e;
            font-size: 14px;
            line-height: (22/14);
            margin-bottom: 25px;
            @include xs {
                font-size: 12px;
            }
        }

        &_form {
            @include md {
                max-width: 80%;
            }
            @include sm {
                max-width: 60%;
                margin: 0 auto;
            }
            @include xs {
                max-width: 100%;
            }
        }

        &_btn {
            width: 100%;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── POSTPROCESS
 * ─────────────────────────────────────────────────────────────── */

.postprocess {
    padding-top: 50px;
    padding-bottom: 110px;
    @include md {
        padding: 25px 0;
    }

    &__title {
        text-align: center;
        margin-bottom: 40px;
    }

    &__pic {
        height: 138px;
        @include bg(center,cover);
        margin-bottom: 15px;
    }

    &__name {
        font-weight: 600;
        @include md {
            font-size: 14px;
        }
        @include xs {
            font-size: 10px;
        }
    }

    &__item {
        @include md {
            margin-bottom: 30px;
        }
        @include sm {
            max-width: 280px;
            margin: 0 auto 30px;
        }
    }

    &__row {
        @include md {
            justify-content: center;
        }
        @include sm {
            margin: 0 -10px;

            .col {
                padding: 0 10px;
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── WORKS
 * ─────────────────────────────────────────────────────────────── */

.works {
    &__sl {
        margin: 65px 0 115px;
        @include sm {
            margin: 30px 0 50px;
        }

        .slide {
            height: 350px;
            @include bg(center,cover);
            @include transit(0.32s);
            outline: none;
            @include lg {
                height: 300px;
            }
            @include md {
                height: 250px;
            }
            @include xs {
                height: 100px;
            }

            &.slick-center {
                transform: scale(1.32);
            }

            a {
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        .slick-track {
            padding: 56px 0;
            @include sm {
                padding: 25px 0;
            }
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;

            &.prev {
                left: 20px;
                transform: translateY(-50%) rotateY(180deg);
                @include sm {
                    left: 5px;
                }
            }

            &.next {
                right: 20px;
                @include sm {
                    right: 5px;
                }
            }
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: -45px;
            left: 0;
            width: 100%;

            li {
                display: block;
                width: 15px;
                height: 15px;
                border: 2px solid #e8e8e8;
                background-color: #fff;
                border-radius: 50%;
                margin: 0 5px;
                @include transit();

                &.slick-active {
                    background-color: #e8e8e8;
                }

                button {
                    display: none;
                }
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── CYCLE
 * ─────────────────────────────────────────────────────────────── */

.cycle {
    padding: 110px 0 120px;
    @include md {
        padding: 50px 0;
    }

    &__top {
        margin-bottom: 40px;
    }

    &__title {
        @include md {
            margin-bottom: 30px;
        }
    }

    &__txt {
        font-size: 17px;
        line-height: (22/17);
        color: #8c8d8e;
    }

    &__col {
        width: (100%/4);
        flex: none;
        @include md {
            width: 50%;
            margin-bottom: 30px;
        }
        @include xs {
            width: 100%;
        }
    }

    &__item {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__body {
        padding: 25px 15px 20px;
        min-height: 150px;
    }

    &__name {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__desc {
        font-size: 13px;
        line-height: (18/13);
        color: #757777;
    }

    &__pic {
        height: 152px;
        @include bg(center,cover);
        position: relative;
    }

    &__icon {
        position: absolute;
        top: 0;
        right: 13px;
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── WORKPATH
 * ─────────────────────────────────────────────────────────────── */

.workpath {
    overflow: hidden;
    padding-top: 70px;

    &__in {
        position: relative;
        @include md {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__title {
        position: relative;
        z-index: 1;
        top: 40px;
        @include md {
            top: 0;
            width: 100%;
            margin-bottom: 50px;
        }
    }

    &__item {
        width: (100%/4);
        min-height: 250px;
        padding-right: 15px;
        @include md {
            width: (100%/3);
            min-height: auto;
            padding-bottom: 15px;
            margin-bottom: 50px;
        }
        @include sm {
            width: 50%;
            border-bottom: 1px solid #e5e7e7;
        }
        @include xs {
            width: 100%;
        }

        &.item1,
        &.item7 {
            margin-left: (100%/4)*3;
            @include md {
                margin-left: 0;
            }
        }

        &.item2,
        &.item6 {
            margin-left: (100%/4)*2;
            @include md {
                margin-left: 0;
            }
        }

        &.item3,
        &.item5 {
            margin-left: (100%/4);
            @include md {
                margin-left: 0;
            }
        }

        &.item2,
        &.item3,
        &.item4 {
            margin-top: -12.3%;
            @include md {
                margin-top: 0;
            }
        }

        &.item5 {
            margin-top: -9.5%;
            @include md {
                margin-top: 0;
            }
        }

        &.item6 {
            margin-top: -12%;
            @include md {
                margin-top: 0;
            }
        }

        &.item7 {
            margin-top: -12.5%;
            @include md {
                margin-top: 0;
            }
        }
    }

    &__header {
        position: relative;
    }

    &__icon {
        position: absolute;
        top: -16px;
        left: 120px;

        .ico {
            width: 69px;
            height: 68px;
            fill: rgba(#e96559,0.3);
        }
    }


    &__num {
        font-size: 60px;
        line-height: 1;
        font-weight: 900;
        color: #ededed;
        padding-left: 18px;
        margin-bottom: 10px;
    }

    &__body {
        padding-left: 18px;
        position: relative;

        &:before {
            @include pseudo();
            top: 0;
            left: 0;
            width: 34px;
            height: 4px;
            background-color: $color-one;
        }
    }

    &__name {
        font-size: 20px;
        font-weight: 600;
        padding-top: 20px;
        margin-bottom: 10px;
        @include md {
            font-size: 18px;
        }
    }

    &__desc {
        font-size: 13px;
        color: #757777;
        line-height: (18/13);
        @include md {
            font-size: 12px;
        }
    }

    .workpath-lines {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        @include md {
            display: none;
        }

        .line {
            width: 1px;
            height: 100%;
            background-color: #f8f8f8;
            position: absolute;
            top: 0;
            left: 0;

            &.line1 {
                left: (100%/4)*3;
            }

            &.line2 {
                left: (100%/4)*2;
            }

            &.line3 {
                left: (100%/4);
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── GARANTY
 * ─────────────────────────────────────────────────────────────── */

.garanty {
    @include bg(center,cover);
    background-image: url($path+'bg-garanty.jpg');

    &__in {
        min-height: 428px;
        color: #fff;
        padding-top: 60px;
        @include sm {
            padding: 50px 0 0;
            min-height: auto;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 60px;
        @include sm {
            margin-bottom: 30px;
        }
    }

    &__row {
        margin: 0;
    }

    &__col {
        flex: none;
        width: (100%/4);
        padding: 0;
        @include sm {
            width: 50%;
            margin-bottom: 30px;
        }
        @include xs {
            width: 100%;
            margin-bottom: 50px;
        }
    }

    &__body {
        padding-top: 62px;
        position: relative;
        @include sm {
            padding: 0 40px;
        }

        &:before {
            @include pseudo();
            @include bg(center,100% auto);
            background-image: url($path+'icons/path.svg');
            top: 0;
            left: (100%/8);
            right: (100%/8);
            height: 60px;
            @include sm {
                display: none;
            }
        }
    }

    &__dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #fff;
        position: relative;
        margin: 0 auto;

        &:before {
            @include pseudo();
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            margin-top: -4px;
            margin-left: -4px;
        }
    }

    &__desc {
        font-size: 17px;
        line-height: (28/17);
        text-align: center;
        padding: 12px 15px 0;
        @include lg {
            font-size: 14px;
            padding: 12px 5px 0;
        }
        @include md {
            padding-top: 5px;
        }
        @include sm {
            font-size: 12px;
        }

        strong {
            font-weight: bold;
            font-size: 20px;
            line-height: (28/20);
            @include md {
                font-size: 16px;
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── CLIENTS
 * ─────────────────────────────────────────────────────────────── */

.clients {
    padding: 115px 0 120px;
    @include md {
        padding: 50px 0;
    }

    &__top {
        margin-bottom: 90px;
    }

    &__title {
        @include sm {
            margin-bottom: 30px;
        }
    }

    &__txt {
        color: #a3a4a5;
        font-size: 17px;
        line-height: (22/17);
        @include sm {
            font-size: 14px;
        }
    }

    &__sl {

        .slide {
            height: 140px;
            outline: none;
            @include sm {
                height: 100px;
            }

            &__in {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 140px;
                    max-width: 100%;
                    @include sm {
                        height: 100px;
                    }
                }
            }
        }

        .slick-arrow {
            position: absolute;
            top: -85px;
            @include sm {
                top: -50px;
            }

            &.prev {
                left: 0;
                @include sm {
                    left: auto;
                    right: 45px;
                }
            }

            &.next {
                left: 45px;
                @include sm {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── FAQ
 * ─────────────────────────────────────────────────────────────── */

.faq {
    padding: 105px 0 70px;
    @include md {
        padding: 50px 0;
    }

    &__title {
        margin-bottom: 80px;
        @include md {
            margin-bottom: 40px;
        }
    }

    &__item {
        border-top: 1px solid #e5e7e7;
        padding: 20px 0 18px;
    }

    &__q {
        font-size: 17px;
        font-weight: 600;
        @include sm {
            margin-bottom: 15px;
            font-size: 16px;
        }
    }

    &__a {
        color: #757777;
        font-size: 15px;
        line-height: (22/15);
        @include sm {
            font-size: 14px;
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── QUESTIONS
 * ─────────────────────────────────────────────────────────────── */

.question {
    @include bg(center,cover);
    background-image: url($path+'bg-question.jpg');
    @include sm {
        text-align: center;
    }

    &__in {
        padding: 65px 0 40px;
        color: #fff;
        @include sm {
            padding: 50px 0 30px;
        }
    }

    &__top {
        margin-bottom: 70px;
        @include sm {
            margin-bottom: 35px;
        }
    }

    &__title {
        @include sm {
            margin-bottom: 30px;
        }
    }

    &__txt {
        @extend .txt;
    }

    &__form {
        @include sm {
            max-width: 60%;
            margin: 0 auto;
        }
        @include xs {
            max-width: 100%;
        }

        &_footer {
            text-align: right;
            @include lg {
                text-align: center;
            }
            @include md {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .sep {
                display: inline-block;
                margin: 0 12px;
                @include md {
                    margin: 15px 0;
                }
            }
        }

        &_btn {
            min-width: 352px;
            @include lg {
                min-width: auto;
            }
            @include md {
                flex: none;
                width: 80%;
            }
            @include xs {
                width: 100%;
            }
        }
    }
}


/* ──────────────────────────────────────────────────────────────────
 * ── CONTACT
 * ─────────────────────────────────────────────────────────────── */

.contact {
    padding: 77px 0 67px;
    @include sm {
        padding: 50px 0;
    }

    &__box {
        height: 100%;
        @include sm {
            margin-bottom: 30px;
        }

        &--map {
            @include sm {
                height: 415px;
            }
        }

        &_in {
            height: 100%;
            background-color: #f6f7f9;
            padding: 67px 77px;
            position: relative;
            @include md {
                padding: 40px 40px 0;
            }
            @include sm {
                padding: 20px 20px 0;
            }
        }
    }

    &__title {
        margin-bottom: 80px;
        @include md {
            margin-bottom: 40px;
        }
    }

    &__item {
        position: relative;
        padding-left: 77px;
        margin-bottom: 45px;
        @include xs {
            padding-left: 60px;
            margin-bottom: 25px;
        }
    }

    &__type {
        font-size: 20px;
        margin-bottom: 15px;
        @include sm {
            font-size: 18px;
        }
    }

    &__txt {
        color: #757777;
        font-size: 14px;
        line-height: (19/14);
        @include sm {
            font-size: 12px;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        .ico {
            fill: $color-one;
            width: 39px;
            height: 41px;
        }
    }
}

.map {
    background-color: #f6f7f9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
