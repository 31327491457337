@charset "UTF-8";
.col {
  box-sizing: border-box;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 20px; }
  .col--no-gutters {
    padding: 0; }

.grid {
  box-sizing: border-box;
  padding: 0 15px; }
  .grid--container {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%; }
  .grid--no-gutters {
    padding: 0; }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -20px;
  padding: 0; }
  .row:last-child {
    margin-bottom: 0; }
  .row--no-gutters {
    margin: 0; }

.text--left {
  text-align: left; }

.text--center {
  text-align: center; }

.text--right {
  text-align: right; }

.row--xs-top {
  align-items: flex-start; }

.row--xs-center {
  align-items: center; }

.row--xs-bottom {
  align-items: flex-end; }

.col--xs-align-top {
  align-self: flex-start; }

.col--xs-align-center {
  align-self: center; }

.col--xs-align-bottom {
  align-self: flex-end; }

.col--xs-first {
  order: -1; }

.col--xs-last {
  order: 1; }

.col--xs-reset {
  order: 0; }

.row--xs-reverse {
  flex-direction: row-reverse; }

.col--xs-flex {
  display: flex; }

.text--xs-right {
  text-align: right; }

.text--xs-left {
  text-align: left; }

.text--xs-center {
  text-align: center; }

.col--xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 1rem .5rem 0 .5rem; }

.col--xs-1 {
  width: 10%;
  flex: none; }

.col--xs-2 {
  width: 20%;
  flex: none; }

.col--xs-3 {
  width: 30%;
  flex: none; }

.col--xs-4 {
  width: 40%;
  flex: none; }

.col--xs-5 {
  width: 50%;
  flex: none; }

.col--xs-6 {
  width: 60%;
  flex: none; }

.col--xs-7 {
  width: 70%;
  flex: none; }

.col--xs-8 {
  width: 80%;
  flex: none; }

.col--xs-9 {
  width: 90%;
  flex: none; }

.col--xs-10 {
  width: 100%;
  flex: none; }

.col--xs-offset-1 {
  margin-left: 10%; }

.col--xs-offset-2 {
  margin-left: 20%; }

.col--xs-offset-3 {
  margin-left: 30%; }

.col--xs-offset-4 {
  margin-left: 40%; }

.col--xs-offset-5 {
  margin-left: 50%; }

.col--xs-offset-6 {
  margin-left: 60%; }

.col--xs-offset-7 {
  margin-left: 70%; }

.col--xs-offset-8 {
  margin-left: 80%; }

.col--xs-offset-9 {
  margin-left: 90%; }

.col--xs-offset-10 {
  margin-left: 100%; }

@media (min-width: 544px) {
  .row--sm-top {
    align-items: flex-start; }
  .row--sm-center {
    align-items: center; }
  .row--sm-bottom {
    align-items: flex-end; }
  .col--sm-align-top {
    align-self: flex-start; }
  .col--sm-align-center {
    align-self: center; }
  .col--sm-align-bottom {
    align-self: flex-end; }
  .col--sm-first {
    order: -1; }
  .col--sm-last {
    order: 1; }
  .col--sm-reset {
    order: 0; }
  .row--sm-reverse {
    flex-direction: row-reverse; }
  .col--sm-flex {
    display: flex; }
  .text--sm-right {
    text-align: right; }
  .text--sm-left {
    text-align: left; }
  .text--sm-center {
    text-align: center; }
  .col--sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem .5rem 0 .5rem; }
  .col--sm-1 {
    width: 10%;
    flex: none; }
  .col--sm-2 {
    width: 20%;
    flex: none; }
  .col--sm-3 {
    width: 30%;
    flex: none; }
  .col--sm-4 {
    width: 40%;
    flex: none; }
  .col--sm-5 {
    width: 50%;
    flex: none; }
  .col--sm-6 {
    width: 60%;
    flex: none; }
  .col--sm-7 {
    width: 70%;
    flex: none; }
  .col--sm-8 {
    width: 80%;
    flex: none; }
  .col--sm-9 {
    width: 90%;
    flex: none; }
  .col--sm-10 {
    width: 100%;
    flex: none; }
  .col--sm-offset-1 {
    margin-left: 10%; }
  .col--sm-offset-2 {
    margin-left: 20%; }
  .col--sm-offset-3 {
    margin-left: 30%; }
  .col--sm-offset-4 {
    margin-left: 40%; }
  .col--sm-offset-5 {
    margin-left: 50%; }
  .col--sm-offset-6 {
    margin-left: 60%; }
  .col--sm-offset-7 {
    margin-left: 70%; }
  .col--sm-offset-8 {
    margin-left: 80%; }
  .col--sm-offset-9 {
    margin-left: 90%; }
  .col--sm-offset-10 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .row--md-top {
    align-items: flex-start; }
  .row--md-center {
    align-items: center; }
  .row--md-bottom {
    align-items: flex-end; }
  .col--md-align-top {
    align-self: flex-start; }
  .col--md-align-center {
    align-self: center; }
  .col--md-align-bottom {
    align-self: flex-end; }
  .col--md-first {
    order: -1; }
  .col--md-last {
    order: 1; }
  .col--md-reset {
    order: 0; }
  .row--md-reverse {
    flex-direction: row-reverse; }
  .col--md-flex {
    display: flex; }
  .text--md-right {
    text-align: right; }
  .text--md-left {
    text-align: left; }
  .text--md-center {
    text-align: center; }
  .col--md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem .5rem 0 .5rem; }
  .col--md-1 {
    width: 10%;
    flex: none; }
  .col--md-2 {
    width: 20%;
    flex: none; }
  .col--md-3 {
    width: 30%;
    flex: none; }
  .col--md-4 {
    width: 40%;
    flex: none; }
  .col--md-5 {
    width: 50%;
    flex: none; }
  .col--md-6 {
    width: 60%;
    flex: none; }
  .col--md-7 {
    width: 70%;
    flex: none; }
  .col--md-8 {
    width: 80%;
    flex: none; }
  .col--md-9 {
    width: 90%;
    flex: none; }
  .col--md-10 {
    width: 100%;
    flex: none; }
  .col--md-offset-1 {
    margin-left: 10%; }
  .col--md-offset-2 {
    margin-left: 20%; }
  .col--md-offset-3 {
    margin-left: 30%; }
  .col--md-offset-4 {
    margin-left: 40%; }
  .col--md-offset-5 {
    margin-left: 50%; }
  .col--md-offset-6 {
    margin-left: 60%; }
  .col--md-offset-7 {
    margin-left: 70%; }
  .col--md-offset-8 {
    margin-left: 80%; }
  .col--md-offset-9 {
    margin-left: 90%; }
  .col--md-offset-10 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .row--lg-top {
    align-items: flex-start; }
  .row--lg-center {
    align-items: center; }
  .row--lg-bottom {
    align-items: flex-end; }
  .col--lg-align-top {
    align-self: flex-start; }
  .col--lg-align-center {
    align-self: center; }
  .col--lg-align-bottom {
    align-self: flex-end; }
  .col--lg-first {
    order: -1; }
  .col--lg-last {
    order: 1; }
  .col--lg-reset {
    order: 0; }
  .row--lg-reverse {
    flex-direction: row-reverse; }
  .col--lg-flex {
    display: flex; }
  .text--lg-right {
    text-align: right; }
  .text--lg-left {
    text-align: left; }
  .text--lg-center {
    text-align: center; }
  .col--lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem .5rem 0 .5rem; }
  .col--lg-1 {
    width: 10%;
    flex: none; }
  .col--lg-2 {
    width: 20%;
    flex: none; }
  .col--lg-3 {
    width: 30%;
    flex: none; }
  .col--lg-4 {
    width: 40%;
    flex: none; }
  .col--lg-5 {
    width: 50%;
    flex: none; }
  .col--lg-6 {
    width: 60%;
    flex: none; }
  .col--lg-7 {
    width: 70%;
    flex: none; }
  .col--lg-8 {
    width: 80%;
    flex: none; }
  .col--lg-9 {
    width: 90%;
    flex: none; }
  .col--lg-10 {
    width: 100%;
    flex: none; }
  .col--lg-offset-1 {
    margin-left: 10%; }
  .col--lg-offset-2 {
    margin-left: 20%; }
  .col--lg-offset-3 {
    margin-left: 30%; }
  .col--lg-offset-4 {
    margin-left: 40%; }
  .col--lg-offset-5 {
    margin-left: 50%; }
  .col--lg-offset-6 {
    margin-left: 60%; }
  .col--lg-offset-7 {
    margin-left: 70%; }
  .col--lg-offset-8 {
    margin-left: 80%; }
  .col--lg-offset-9 {
    margin-left: 90%; }
  .col--lg-offset-10 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .row--xl-top {
    align-items: flex-start; }
  .row--xl-center {
    align-items: center; }
  .row--xl-bottom {
    align-items: flex-end; }
  .col--xl-align-top {
    align-self: flex-start; }
  .col--xl-align-center {
    align-self: center; }
  .col--xl-align-bottom {
    align-self: flex-end; }
  .col--xl-first {
    order: -1; }
  .col--xl-last {
    order: 1; }
  .col--xl-reset {
    order: 0; }
  .row--xl-reverse {
    flex-direction: row-reverse; }
  .col--xl-flex {
    display: flex; }
  .text--xl-right {
    text-align: right; }
  .text--xl-left {
    text-align: left; }
  .text--xl-center {
    text-align: center; }
  .col--xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem .5rem 0 .5rem; }
  .col--xl-1 {
    width: 10%;
    flex: none; }
  .col--xl-2 {
    width: 20%;
    flex: none; }
  .col--xl-3 {
    width: 30%;
    flex: none; }
  .col--xl-4 {
    width: 40%;
    flex: none; }
  .col--xl-5 {
    width: 50%;
    flex: none; }
  .col--xl-6 {
    width: 60%;
    flex: none; }
  .col--xl-7 {
    width: 70%;
    flex: none; }
  .col--xl-8 {
    width: 80%;
    flex: none; }
  .col--xl-9 {
    width: 90%;
    flex: none; }
  .col--xl-10 {
    width: 100%;
    flex: none; }
  .col--xl-offset-1 {
    margin-left: 10%; }
  .col--xl-offset-2 {
    margin-left: 20%; }
  .col--xl-offset-3 {
    margin-left: 30%; }
  .col--xl-offset-4 {
    margin-left: 40%; }
  .col--xl-offset-5 {
    margin-left: 50%; }
  .col--xl-offset-6 {
    margin-left: 60%; }
  .col--xl-offset-7 {
    margin-left: 70%; }
  .col--xl-offset-8 {
    margin-left: 80%; }
  .col--xl-offset-9 {
    margin-left: 90%; }
  .col--xl-offset-10 {
    margin-left: 100%; } }

/* ──────────────────────────────────────────────────────────────────
 * ── FONTS
 * ─────────────────────────────────────────────────────────────── */
@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../fonts/hinted-Montserrat-Regular.woff2") format("woff2"), url("../fonts/hinted-Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../fonts/hinted-Montserrat-Medium.woff2") format("woff2"), url("../fonts/hinted-Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../fonts/hinted-Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/hinted-Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/hinted-Montserrat-Bold.woff2") format("woff2"), url("../fonts/hinted-Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url("../fonts/hinted-Montserrat-ExtraBold.woff2") format("woff2"), url("../fonts/hinted-Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  @font-face font-family: $fontName;
  src: local("Montserrat Black"), local("Montserrat-Black"), url("../fonts/hinted-Montserrat-Black.woff2") format("woff2"), url("../fonts/hinted-Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

/*
 * ─── START ───────────────────────────────────────────────────────────
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body, html {
  padding: 0;
  border: 0;
  vertical-align: baseline;
  width: 100%;
  overflow-x: hidden;
  min-height: 100%; }

html {
  font-size: 16px; }

body {
  font-family: "Montserrat", "Arial", "Helvetica", sans-serif;
  color: #191c1d;
  font-weight: normal;
  background-color: #fff;
  line-height: normal;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

/*
 * ─── BROWSEHAPPY ───────────────────────────────────────────────────────────
*/
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #b71c1c;
  color: #fff;
  font-size: 18px;
  line-height: 1.4; }
  .browsehappy a,
  .browsehappy a:hover {
    color: #fff;
    text-decoration: underline; }

/*
 * ─── FOOTER TO BOTTOM ───────────────────────────────────────────────────────────
*/
.main {
  flex-grow: 1; }

/*
 * ─── LINKS ───────────────────────────────────────────────────────────
*/
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.25s ease-out; }
  a:hover {
    color: color-base;
    text-decoration: none;
    border-bottom-color: color-base; }

/*
 * ─── BASIC ───────────────────────────────────────────────────────────
*/
a,
button,
input,
textarea {
  outline: none;
  transition: all 0.25s ease-out; }
  a:focus,
  button:focus,
  input:focus,
  textarea:focus {
    outline: none; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

button {
  display: inline-block;
  border: 0;
  background: transparent;
  font-family: inherit; }

button, button[type="submit"],
input[type="submit"] {
  cursor: pointer; }

input, textarea {
  display: block;
  width: 100%;
  line-height: normal;
  border: 0;
  background-color: transparent;
  font-family: inherit; }

input[type="search"] {
  -webkit-appearance: none; }

textarea {
  width: 100%;
  resize: none; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-weight: inherit; }

.hide {
  display: none; }

.hidden {
  opacity: 0;
  visibility: 0; }

.iframe {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden; }
  .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.help-block {
  color: #b71c1c;
  font-size: 0.8rem; }
  @media (max-width: 991px) {
    .help-block {
      font-size: 0.7rem; } }

[data-tab-content] {
  @nclude hidden();
  max-height: 0; }
  [data-tab-content].active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
    max-height: none; }

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; }

.table {
  overflow-x: auto; }

/*
 * ─── PLUGINS ───────────────────────────────────────────────────────────
*/
.fancybox-close-small {
  display: none; }

.fancybox-container {
  font-family: inherit; }

/* ──────────────────────────────────────────────────────────────────
 * ── BUTTON
 * ─────────────────────────────────────────────────────────────── */
.btn, .btn-dark {
  background-color: #e96559;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  height: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  padding: 1px 30px 0;
  border-radius: 50px;
  transition: all 0.25s ease-out; }
  @media (max-width: 991px) {
    .btn, .btn-dark {
      height: 48px; } }
  @media (max-width: 767px) {
    .btn, .btn-dark {
      line-height: 1.4;
      font-size: 12px; } }
  .btn:hover, .btn-dark:hover {
    opacity: 0.8; }
  .btn:disabled, .btn-dark:disabled {
    opacity: 0.6;
    cursor: not-allowed; }

.btn-dark {
  background-color: #36393a; }
  .btn-dark:hover {
    background-color: #111212;
    opacity: 1; }

/* ──────────────────────────────────────────────────────────────────
 * ── TITLE
 * ─────────────────────────────────────────────────────────────── */
.title, .title-light, .title-lg {
  font-size: 34px;
  font-weight: 800;
  line-height: 1.17647; }
  @media (max-width: 991px) {
    .title, .title-light, .title-lg {
      font-size: 30px; } }
  .title i, .title-light i, .title-lg i {
    color: #e96559;
    font-style: normal; }

.title-light {
  color: #fff; }

.title-lg {
  font-size: 48px; }
  @media (max-width: 991px) {
    .title-lg {
      font-size: 38px; } }
  @media (max-width: 767px) {
    .title-lg {
      font-size: 30px; } }

.title-sm {
  font-size: 28px;
  font-weight: bold; }

/* ──────────────────────────────────────────────────────────────────
 * ── SECTIONS
 * ─────────────────────────────────────────────────────────────── */
.section {
  padding: 90px 0 0; }
  @media (max-width: 991px) {
    .section {
      padding: 45px 0; } }

.section-colored {
  background-color: #f6f7f9; }

.txt, .question__txt {
  font-size: 17px;
  line-height: 1.29412; }

/* ──────────────────────────────────────────────────────────────────
 * ── FLAG
 * ─────────────────────────────────────────────────────────────── */
.flag {
  width: 80px;
  height: 95px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% auto;
  background-image: url("../img/icons/flag-dark.svg"); }
  @media (max-width: 767px) {
    .flag {
      width: 60px;
      height: 70px; } }
  .flag .ico {
    fill: #fff;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%); }
    @media (max-width: 767px) {
      .flag .ico {
        width: 30px;
        height: 30px; } }
  .flag--red {
    background-image: url("../img/icons/flag-red.svg"); }

/* ──────────────────────────────────────────────────────────────────
 * ── INPUT
 * ─────────────────────────────────────────────────────────────── */
.form-group {
  margin-bottom: 27px;
  position: relative; }
  .form-group .help-block {
    position: absolute;
    bottom: 2px;
    right: 2px;
    font-size: 0.7em; }

.form {
  position: relative; }
  .form.success .form-success {
    opacity: 1;
    visibility: visible;
    z-index: 1; }

.form-success {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.6;
  z-index: 10;
  color: #191c1d;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.25s ease-out; }

.input, .input-dark {
  height: 58px;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
  font-size: 14px; }
  @media (max-width: 767px) {
    .input, .input-dark {
      height: 48px; } }
  .input::placeholder, .input-dark::placeholder {
    color: #8c8d8e; }

.input-dark {
  background-color: #f6f7f9; }

.cbox {
  position: relative; }
  .cbox__in {
    display: flex;
    align-items: center; }
  .cbox__input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden; }
  .cbox__ctrl {
    width: 20px;
    height: 20px;
    flex: none;
    border-radius: 3px;
    border: 1px solid #c9c9ca;
    background-color: #f6f7f9;
    margin-right: 10px;
    position: relative; }
    .cbox__ctrl:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #36393a;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      transition: all 0.25s ease-out; }
  .cbox__label {
    font-size: 11px;
    color: #8c8d8e; }
  .cbox__input:checked ~ .cbox__in .cbox__ctrl:before {
    opacity: 1;
    visibility: visible;
    z-index: 1; }
  .cbox .help-block {
    flex: none;
    width: 100%;
    position: relative;
    bottom: auto;
    right: auto;
    text-align: right; }

/* ──────────────────────────────────────────────────────────────────
 * ── SLICK
 * ─────────────────────────────────────────────────────────────── */
.slick-slide {
  outline: none; }

.slick-arrow {
  display: block;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: rgba(209, 210, 210, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 16px;
  background-image: url("../img/icons/chevron-right.svg");
  cursor: pointer;
  transition: all 0.25s ease-out; }
  .slick-arrow:hover {
    background-color: #d1d2d2; }
  .slick-arrow.prev {
    transform: rotateY(180deg); }

/* ──────────────────────────────────────────────────────────────────
 * ── THANKS
 * ─────────────────────────────────────────────────────────────── */
.thanks {
  max-width: 700px;
  margin: 100px auto;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  padding: 50px 30px;
  text-align: center;
  line-height: 1.6; }
  @media (max-width: 991px) {
    .thanks {
      margin: 50px auto; } }
  @media (max-width: 767px) {
    .thanks {
      margin: 25px auto; } }
  .thanks__title {
    margin-bottom: 30px; }
  .thanks__ico {
    width: 100px;
    height: 100px;
    fill: #e96559;
    margin-bottom: 50px; }

/* ──────────────────────────────────────────────────────────────────
 * ── ICONS
 * ─────────────────────────────────────────────────────────────── */
.ico {
  display: block;
  transition: all 0.25s ease-out; }

/* ──────────────────────────────────────────────────────────────────
 * ── HEADER
 * ─────────────────────────────────────────────────────────────── */
.header {
  background-color: #fff;
  position: relative;
  padding-top: 9px;
  min-height: 130px; }
  @media (max-width: 991px) {
    .header {
      min-height: 67px; } }
  .header:before {
    content: '';
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #E96559 0%, #f58b41 50%, #E96559 100%); }
  .header__wrapper {
    background-color: #fff;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.57); }
  .header__in {
    min-height: 130px;
    transition: all 0.25s ease-out; }
    @media (max-width: 991px) {
      .header__in {
        min-height: auto;
        padding: 10px 0; } }
  .header__right {
    text-align: right; }

.headroom {
  width: 100%;
  transition: all 0.25s ease-out; }
  .headroom--not-top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.57); }
    .headroom--not-top .header__in {
      min-height: auto;
      padding: 5px 0; }

.h-logo {
  max-width: 236px; }
  @media (max-width: 991px) {
    .h-logo {
      max-width: 150px; } }
  .h-logo img {
    max-width: 100%; }

.header-contact {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 767px) {
    .header-contact {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end; } }
  .header-contact__item:not(:last-child) {
    margin-right: 2em; }
    @media (max-width: 767px) {
      .header-contact__item:not(:last-child) {
        margin-right: 0;
        margin-bottom: 0.5em; } }
  .header-contact__link {
    display: flex;
    align-items: center;
    font-size: 1.125em;
    line-height: 1; }
    @media (max-width: 991px) {
      .header-contact__link {
        font-size: 0.8em; } }
    .header-contact__link > span {
      display: flex;
      align-items: center;
      line-height: 1; }
      .header-contact__link > span span {
        display: inline-block;
        font-size: 1.66667em;
        font-style: normal;
        margin-left: 0.3em; }
        @media (max-width: 767px) {
          .header-contact__link > span span {
            font-size: 1.22222em; } }
    .header-contact__link .ico {
      width: 18px;
      height: 18px;
      fill: #e96559;
      margin-right: 0.5em; }
      @media (max-width: 991px) {
        .header-contact__link .ico {
          width: 16px;
          height: 16px; } }
    .header-contact__link:hover {
      color: #e96559; }

/* ──────────────────────────────────────────────────────────────────
 * ── FOOTER
 * ─────────────────────────────────────────────────────────────── */
.footer {
  background-color: #f6f7f9; }
  .footer__in {
    height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.f-copyright {
  font-size: 12px;
  color: #a3a4a5; }

.f-socials {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .f-socials__item:not(:first-child) {
    margin-left: 30px; }
    @media (max-width: 767px) {
      .f-socials__item:not(:first-child) {
        margin-left: 15px; } }
  .f-socials__item .ico {
    fill: #36393a;
    transition: all 0.25s ease-out; }
    .f-socials__item .ico:hover {
      opacity: 0.6; }
    .f-socials__item .ico.fb {
      width: 8px;
      height: 16px; }
    .f-socials__item .ico.vk {
      width: 21px;
      height: 12px; }
    .f-socials__item .ico.insta {
      width: 18px;
      height: 18px; }

.modal {
  background-color: #fff;
  position: relative;
  padding: 50px 30px 25px;
  width: 556px; }
  @media (max-width: 767px) {
    .modal {
      padding: 50px 15px 20px;
      width: 90%; } }
  .modal__close {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f6f7f9;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .modal__close .ico {
      width: 12px;
      height: 12px;
      fill: #8c8d8e; }
  .modal__title {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .modal__title {
        font-size: 18px; } }
  .modal__txt {
    font-size: 15px;
    color: #8c8d8e;
    line-height: 1.46667;
    text-align: center;
    margin-bottom: 90px; }
    @media (max-width: 767px) {
      .modal__txt {
        font-size: 12px;
        margin-bottom: 30px; } }
  .modal__form {
    max-width: 75%;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .modal__form {
        max-width: 100%; } }
    .modal__form .form-group {
      margin-bottom: 16px; }
    .modal__form_ctrl {
      margin-top: 30px;
      margin-bottom: 20px; }
    .modal__form .btn, .modal__form .btn-dark {
      width: 100%; }

/* ──────────────────────────────────────────────────────────────────
 * ── PAGE: INDEX
 * ─────────────────────────────────────────────────────────────── */
/* ──────────────────────────────────────────────────────────────────
 * ── HERO
 * ─────────────────────────────────────────────────────────────── */
.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/bg-hero.jpg"); }
  @media (max-width: 767px) {
    .hero {
      background-repeat: no-repeat;
      background-position: left center;
      background-size: cover; } }
  .hero__in {
    min-height: 583px; }
    @media (max-width: 767px) {
      .hero__in {
        min-height: 420px;
        text-align: center; } }
    @media (max-width: 543px) {
      .hero__in {
        min-height: 440px; } }
  .hero__body {
    color: #fff;
    margin-top: 85px; }
    @media (max-width: 767px) {
      .hero__body {
        margin-top: 45px; } }
  .hero__title {
    font-size: 46px;
    font-weight: 800;
    margin-bottom: 20px; }
    @media (max-width: 767px) {
      .hero__title {
        font-size: 25px; } }
  .hero__subtitle {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 32px; }
    .hero__subtitle i {
      font-size: 25px;
      font-weight: 800;
      color: #e96559;
      font-style: normal;
      text-transform: uppercase;
      margin: 0 4px; }
      @media (max-width: 767px) {
        .hero__subtitle i {
          font-size: 16px; } }
  .hero__txt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 30px;
    max-width: 65%; }
    @media (max-width: 1199px) {
      .hero__txt {
        max-width: 40%; } }
    @media (max-width: 767px) {
      .hero__txt {
        font-size: 12px;
        max-width: 100%;
        line-height: 1.6; } }
  .hero__btn {
    min-width: 358px; }
    @media (max-width: 767px) {
      .hero__btn {
        min-width: auto; } }

/* ──────────────────────────────────────────────────────────────────
 * ── ADVANTAGE
 * ─────────────────────────────────────────────────────────────── */
.advantage {
  overflow-x: hidden;
  margin-top: -65px;
  margin-bottom: 78px; }
  @media (max-width: 991px) {
    .advantage {
      margin-bottom: 30px; } }
  .advantage__in {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding-bottom: 30px; }
  .advantage__item {
    width: 25%;
    padding: 0 20px;
    min-height: 120px; }
    @media (max-width: 991px) {
      .advantage__item {
        width: 50%;
        margin-bottom: 15px; } }
    @media (max-width: 543px) {
      .advantage__item {
        width: 50%;
        min-height: 100px;
        padding: 0 10px; } }
    @media (max-width: 400px) {
      .advantage__item {
        width: 100%; } }
  .advantage__body {
    background-color: #f6f7f9;
    box-shadow: 0px 3px 21px 0px rgba(140, 145, 155, 0.35);
    border-radius: 8px;
    padding: 10px 105px 10px 5px;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative; }
    @media (max-width: 1199px) {
      .advantage__body {
        padding-right: 95px; } }
    @media (max-width: 767px) {
      .advantage__body {
        padding-right: 76px; } }
  .advantage__txt {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    text-align: center; }
    @media (max-width: 1199px) {
      .advantage__txt {
        font-size: 12px;
        padding: 0 10px; } }
    @media (max-width: 767px) {
      .advantage__txt {
        font-size: 10px; } }
    @media (max-width: 400px) {
      .advantage__txt {
        font-size: 14px; } }
  .advantage__icon {
    position: absolute;
    top: 0;
    right: 26px;
    z-index: 1; }
    @media (max-width: 1199px) {
      .advantage__icon {
        right: 15px; } }

/* ──────────────────────────────────────────────────────────────────
 * ── GOODS
 * ─────────────────────────────────────────────────────────────── */
.goods__title {
  margin-bottom: 70px; }
  @media (max-width: 991px) {
    .goods__title {
      margin-bottom: 35px; } }

@media (max-width: 991px) {
  .goods__row {
    justify-content: center; } }

@media (max-width: 767px) {
  .goods__row {
    margin: 0 -10px; } }

.goods__col {
  padding-bottom: 78px; }
  @media (max-width: 991px) {
    .goods__col {
      padding-bottom: 35px; } }
  @media (max-width: 767px) {
    .goods__col {
      padding: 0 10px 35px; } }

.goods__item {
  display: block; }
  @media (max-width: 543px) {
    .goods__item {
      max-width: 280px;
      margin: 0 auto; } }

.goods__pic {
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 25px; }
  @media (max-width: 991px) {
    .goods__pic {
      margin-bottom: 15px; } }
  @media (max-width: 767px) {
    .goods__pic {
      height: 150px; } }

.goods__name {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.29412; }
  @media (max-width: 991px) {
    .goods__name {
      font-size: 14px; } }
  @media (max-width: 543px) {
    .goods__name {
      font-size: 10px; } }

/* ──────────────────────────────────────────────────────────────────
 * ── BENEFIT
 * ─────────────────────────────────────────────────────────────── */
.benefit {
  padding: 100px 0 0; }
  @media (max-width: 991px) {
    .benefit {
      padding: 60px 0 0; } }
  .benefit__in {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -17px;
    padding: 100px 0 120px; }
    @media (max-width: 991px) {
      .benefit__in {
        padding: 50px 0; } }
  .benefit__item {
    padding: 0 17px;
    width: 33.33333%;
    padding-bottom: 33px; }
    @media (max-width: 991px) {
      .benefit__item {
        width: 50%; } }
    @media (max-width: 767px) {
      .benefit__item {
        width: 100%;
        padding-bottom: 15px; } }
  .benefit__body {
    min-height: 120px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 21px 0px rgba(140, 145, 155, 0.15);
    border-radius: 8px;
    padding: 15px 15px 25px 116px;
    position: relative; }
    .benefit__body:before {
      content: '';
      display: block;
      position: absolute;
      width: 54px;
      height: 54px;
      background-color: #ededed;
      border-radius: 50%;
      top: 20px;
      left: 20px; }
    .benefit__body:after {
      content: '';
      display: block;
      position: absolute;
      width: 34px;
      height: 4px;
      background-color: #e96559;
      top: 20px;
      right: 0; }
    @media (max-width: 543px) {
      .benefit__body {
        min-height: 100px; } }
  .benefit__txt {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.29412; }
    @media (max-width: 991px) {
      .benefit__txt {
        font-size: 14px; } }
    .benefit__txt i {
      font-size: 14px;
      font-weight: normal;
      font-style: normal; }
  .benefit__icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%); }
    .benefit__icon .ico {
      width: 68px;
      height: 69px;
      fill: #36393a; }

/* ──────────────────────────────────────────────────────────────────
 * ── CALC
 * ─────────────────────────────────────────────────────────────── */
.calc {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/bg-calc.jpg"); }
  .calc__in {
    position: relative;
    padding: 140px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff; }
    @media (max-width: 991px) {
      .calc__in {
        text-align: center;
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 767px) {
      .calc__in {
        padding: 50px 0; } }
  .calc__icon {
    position: absolute;
    top: 0;
    right: 0; }
    @media (max-width: 767px) {
      .calc__icon {
        display: none; } }
  .calc__title {
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .calc__title {
        text-align: center; } }
  .calc__txt {
    font-size: 17px;
    line-height: 1.52941;
    margin-bottom: 90px; }
    @media (max-width: 767px) {
      .calc__txt {
        margin-bottom: 30px;
        text-align: center; } }
  .calc__btn {
    min-width: 352px; }
    @media (max-width: 1199px) {
      .calc__btn {
        min-width: auto; } }

/* ──────────────────────────────────────────────────────────────────
 * ── TECH
 * ─────────────────────────────────────────────────────────────── */
.tech {
  padding: 100px 0 50px; }
  @media (max-width: 991px) {
    .tech {
      padding: 50px 0 25px; } }
  @media (max-width: 991px) {
    .tech__title {
      margin-bottom: 30px; } }
  .tech__body {
    margin-bottom: 45px; }
  .tech__txt {
    font-size: 17px;
    line-height: 1.29412;
    color: #8c8d8e; }
    .tech__txt p + p {
      margin-top: 1.5em; }
  .tech__img {
    height: 325px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("../img/printer.png"); }
    @media (max-width: 767px) {
      .tech__img {
        height: 225px; } }
    @media (max-width: 543px) {
      .tech__img {
        display: none; } }

/* ──────────────────────────────────────────────────────────────────
 * ── SPECIAL
 * ─────────────────────────────────────────────────────────────── */
.special {
  position: relative;
  background-image: linear-gradient(to bottom, #FFFFFF 0%, #F6F7F9 100%); }
  .special:before {
    content: '';
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../img/bg-special.jpg");
    top: 40px;
    bottom: 40px;
    left: 0;
    width: 100%;
    z-index: 0; }
  .special__body {
    max-width: 674px;
    margin: 0 auto;
    padding: 40px 80px 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../img/bg-special-2.jpg");
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1; }
    @media (max-width: 767px) {
      .special__body {
        padding: 40px 30px; } }
    @media (max-width: 543px) {
      .special__body {
        padding: 20px 30px; } }
  .special__top {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-weight: 800;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    background-image: url("../img/icons/special-frame.svg");
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fcbe5d;
    max-width: 400px;
    margin: 0 auto 30px;
    text-align: center; }
    @media (max-width: 543px) {
      .special__top {
        font-size: 10px;
        letter-spacing: 0.1em;
        background-size: 100% 100%;
        margin-bottom: 15px; } }
  .special__title {
    text-align: center;
    margin-bottom: 15px; }
    @media (max-width: 543px) {
      .special__title {
        font-size: 20px; } }
  .special__txt {
    font-size: 15px;
    line-height: 1.46667;
    margin-bottom: 40px; }
    @media (max-width: 543px) {
      .special__txt {
        font-size: 12px;
        margin-bottom: 20px; } }
  .special__form_group {
    max-width: 358px;
    margin: 0 auto 25px; }
  .special__form_btn {
    min-width: 394px; }
    @media (max-width: 767px) {
      .special__form_btn {
        min-width: 100%; } }

/* ──────────────────────────────────────────────────────────────────
 * ── MATERIAL
 * ─────────────────────────────────────────────────────────────── */
.material {
  padding-bottom: 22px; }
  @media (max-width: 991px) {
    .material {
      padding-bottom: 0; } }
  @media (max-width: 543px) {
    .material {
      padding-bottom: 30px; } }
  .material__in {
    position: relative; }
  .material__left {
    padding: 130px 0 0; }
    @media (max-width: 991px) {
      .material__left {
        padding-top: 60px; } }
  .material__right {
    padding-top: 50px; }
    @media (max-width: 767px) {
      .material__right {
        padding-top: 0;
        padding-bottom: 30px; } }
  .material__title {
    margin-bottom: 45px; }
  .material__txt {
    font-size: 17px;
    line-height: 1.29412;
    color: #8c8d8e;
    margin-bottom: 115px; }
    @media (max-width: 991px) {
      .material__txt {
        margin-bottom: 50px; } }
  .material__subtitle {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px; }
  @media (max-width: 991px) {
    .material__row {
      justify-content: center; } }
  @media (max-width: 767px) {
    .material__row {
      margin: 0 -10px; }
      .material__row .col {
        padding: 0 10px; } }
  .material__item {
    max-width: 238px;
    margin: 0 auto;
    padding-bottom: 23px; }
  .material__pic {
    height: 138px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 16px; }
  .material__name {
    font-weight: 600; }
    @media (max-width: 991px) {
      .material__name {
        font-size: 14px; } }
    @media (max-width: 543px) {
      .material__name {
        font-size: 10px; } }
  .material__box {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 50px 120px 47px 0;
    border-radius: 0 12px 12px 0;
    width: 39%; }
    @media (max-width: 1199px) {
      .material__box {
        width: 45%; } }
    @media (max-width: 991px) {
      .material__box {
        position: relative;
        bottom: auto;
        left: auto;
        width: 100%;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .material__box {
        max-width: 100%;
        padding: 25px 25px 25px 0;
        text-align: center; } }
    @media (max-width: 543px) {
      .material__box {
        padding: 55px 25px 25px 10px; } }
    .material__box:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1000px;
      background-color: #fff;
      height: 100%;
      border-radius: 0 12px 12px 0;
      z-index: 0; }
    .material__box_body {
      position: relative;
      z-index: 1; }
    .material__box_icon {
      position: absolute;
      top: 0;
      right: 36px; }
      @media (max-width: 767px) {
        .material__box_icon {
          display: none; } }
    .material__box_title {
      font-size: 24px;
      line-height: 1.33333;
      margin-bottom: 33px; }
      @media (max-width: 543px) {
        .material__box_title {
          font-size: 20px;
          margin-bottom: 15px; } }
      .material__box_title strong {
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.05em; }
    .material__box_txt {
      color: #8c8d8e;
      font-size: 14px;
      line-height: 1.57143;
      margin-bottom: 25px; }
      @media (max-width: 543px) {
        .material__box_txt {
          font-size: 12px; } }
    @media (max-width: 991px) {
      .material__box_form {
        max-width: 80%; } }
    @media (max-width: 767px) {
      .material__box_form {
        max-width: 60%;
        margin: 0 auto; } }
    @media (max-width: 543px) {
      .material__box_form {
        max-width: 100%; } }
    .material__box_btn {
      width: 100%; }

/* ──────────────────────────────────────────────────────────────────
 * ── POSTPROCESS
 * ─────────────────────────────────────────────────────────────── */
.postprocess {
  padding-top: 50px;
  padding-bottom: 110px; }
  @media (max-width: 991px) {
    .postprocess {
      padding: 25px 0; } }
  .postprocess__title {
    text-align: center;
    margin-bottom: 40px; }
  .postprocess__pic {
    height: 138px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 15px; }
  .postprocess__name {
    font-weight: 600; }
    @media (max-width: 991px) {
      .postprocess__name {
        font-size: 14px; } }
    @media (max-width: 543px) {
      .postprocess__name {
        font-size: 10px; } }
  @media (max-width: 991px) {
    .postprocess__item {
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .postprocess__item {
      max-width: 280px;
      margin: 0 auto 30px; } }
  @media (max-width: 991px) {
    .postprocess__row {
      justify-content: center; } }
  @media (max-width: 767px) {
    .postprocess__row {
      margin: 0 -10px; }
      .postprocess__row .col {
        padding: 0 10px; } }

/* ──────────────────────────────────────────────────────────────────
 * ── WORKS
 * ─────────────────────────────────────────────────────────────── */
.works__sl {
  margin: 65px 0 115px; }
  @media (max-width: 767px) {
    .works__sl {
      margin: 30px 0 50px; } }
  .works__sl .slide {
    height: 350px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.32s ease-out;
    outline: none; }
    @media (max-width: 1199px) {
      .works__sl .slide {
        height: 300px; } }
    @media (max-width: 991px) {
      .works__sl .slide {
        height: 250px; } }
    @media (max-width: 543px) {
      .works__sl .slide {
        height: 100px; } }
    .works__sl .slide.slick-center {
      transform: scale(1.32); }
    .works__sl .slide a {
      display: block;
      height: 100%;
      width: 100%; }
  .works__sl .slick-track {
    padding: 56px 0; }
    @media (max-width: 767px) {
      .works__sl .slick-track {
        padding: 25px 0; } }
  .works__sl .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2; }
    .works__sl .slick-arrow.prev {
      left: 20px;
      transform: translateY(-50%) rotateY(180deg); }
      @media (max-width: 767px) {
        .works__sl .slick-arrow.prev {
          left: 5px; } }
    .works__sl .slick-arrow.next {
      right: 20px; }
      @media (max-width: 767px) {
        .works__sl .slick-arrow.next {
          right: 5px; } }
  .works__sl .slick-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%; }
    .works__sl .slick-dots li {
      display: block;
      width: 15px;
      height: 15px;
      border: 2px solid #e8e8e8;
      background-color: #fff;
      border-radius: 50%;
      margin: 0 5px;
      transition: all 0.25s ease-out; }
      .works__sl .slick-dots li.slick-active {
        background-color: #e8e8e8; }
      .works__sl .slick-dots li button {
        display: none; }

/* ──────────────────────────────────────────────────────────────────
 * ── CYCLE
 * ─────────────────────────────────────────────────────────────── */
.cycle {
  padding: 110px 0 120px; }
  @media (max-width: 991px) {
    .cycle {
      padding: 50px 0; } }
  .cycle__top {
    margin-bottom: 40px; }
  @media (max-width: 991px) {
    .cycle__title {
      margin-bottom: 30px; } }
  .cycle__txt {
    font-size: 17px;
    line-height: 1.29412;
    color: #8c8d8e; }
  .cycle__col {
    width: 25%;
    flex: none; }
    @media (max-width: 991px) {
      .cycle__col {
        width: 50%;
        margin-bottom: 30px; } }
    @media (max-width: 543px) {
      .cycle__col {
        width: 100%; } }
  .cycle__item {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  .cycle__body {
    padding: 25px 15px 20px;
    min-height: 150px; }
  .cycle__name {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 10px; }
  .cycle__desc {
    font-size: 13px;
    line-height: 1.38462;
    color: #757777; }
  .cycle__pic {
    height: 152px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative; }
  .cycle__icon {
    position: absolute;
    top: 0;
    right: 13px; }

/* ──────────────────────────────────────────────────────────────────
 * ── WORKPATH
 * ─────────────────────────────────────────────────────────────── */
.workpath {
  overflow: hidden;
  padding-top: 70px; }
  .workpath__in {
    position: relative; }
    @media (max-width: 991px) {
      .workpath__in {
        display: flex;
        flex-wrap: wrap; } }
  .workpath__title {
    position: relative;
    z-index: 1;
    top: 40px; }
    @media (max-width: 991px) {
      .workpath__title {
        top: 0;
        width: 100%;
        margin-bottom: 50px; } }
  .workpath__item {
    width: 25%;
    min-height: 250px;
    padding-right: 15px; }
    @media (max-width: 991px) {
      .workpath__item {
        width: 33.33333%;
        min-height: auto;
        padding-bottom: 15px;
        margin-bottom: 50px; } }
    @media (max-width: 767px) {
      .workpath__item {
        width: 50%;
        border-bottom: 1px solid #e5e7e7; } }
    @media (max-width: 543px) {
      .workpath__item {
        width: 100%; } }
    .workpath__item.item1, .workpath__item.item7 {
      margin-left: 75%; }
      @media (max-width: 991px) {
        .workpath__item.item1, .workpath__item.item7 {
          margin-left: 0; } }
    .workpath__item.item2, .workpath__item.item6 {
      margin-left: 50%; }
      @media (max-width: 991px) {
        .workpath__item.item2, .workpath__item.item6 {
          margin-left: 0; } }
    .workpath__item.item3, .workpath__item.item5 {
      margin-left: 25%; }
      @media (max-width: 991px) {
        .workpath__item.item3, .workpath__item.item5 {
          margin-left: 0; } }
    .workpath__item.item2, .workpath__item.item3, .workpath__item.item4 {
      margin-top: -12.3%; }
      @media (max-width: 991px) {
        .workpath__item.item2, .workpath__item.item3, .workpath__item.item4 {
          margin-top: 0; } }
    .workpath__item.item5 {
      margin-top: -9.5%; }
      @media (max-width: 991px) {
        .workpath__item.item5 {
          margin-top: 0; } }
    .workpath__item.item6 {
      margin-top: -12%; }
      @media (max-width: 991px) {
        .workpath__item.item6 {
          margin-top: 0; } }
    .workpath__item.item7 {
      margin-top: -12.5%; }
      @media (max-width: 991px) {
        .workpath__item.item7 {
          margin-top: 0; } }
  .workpath__header {
    position: relative; }
  .workpath__icon {
    position: absolute;
    top: -16px;
    left: 120px; }
    .workpath__icon .ico {
      width: 69px;
      height: 68px;
      fill: rgba(233, 101, 89, 0.3); }
  .workpath__num {
    font-size: 60px;
    line-height: 1;
    font-weight: 900;
    color: #ededed;
    padding-left: 18px;
    margin-bottom: 10px; }
  .workpath__body {
    padding-left: 18px;
    position: relative; }
    .workpath__body:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 34px;
      height: 4px;
      background-color: #e96559; }
  .workpath__name {
    font-size: 20px;
    font-weight: 600;
    padding-top: 20px;
    margin-bottom: 10px; }
    @media (max-width: 991px) {
      .workpath__name {
        font-size: 18px; } }
  .workpath__desc {
    font-size: 13px;
    color: #757777;
    line-height: 1.38462; }
    @media (max-width: 991px) {
      .workpath__desc {
        font-size: 12px; } }
  .workpath .workpath-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    @media (max-width: 991px) {
      .workpath .workpath-lines {
        display: none; } }
    .workpath .workpath-lines .line {
      width: 1px;
      height: 100%;
      background-color: #f8f8f8;
      position: absolute;
      top: 0;
      left: 0; }
      .workpath .workpath-lines .line.line1 {
        left: 75%; }
      .workpath .workpath-lines .line.line2 {
        left: 50%; }
      .workpath .workpath-lines .line.line3 {
        left: 25%; }

/* ──────────────────────────────────────────────────────────────────
 * ── GARANTY
 * ─────────────────────────────────────────────────────────────── */
.garanty {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/bg-garanty.jpg"); }
  .garanty__in {
    min-height: 428px;
    color: #fff;
    padding-top: 60px; }
    @media (max-width: 767px) {
      .garanty__in {
        padding: 50px 0 0;
        min-height: auto; } }
  .garanty__title {
    text-align: center;
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .garanty__title {
        margin-bottom: 30px; } }
  .garanty__row {
    margin: 0; }
  .garanty__col {
    flex: none;
    width: 25%;
    padding: 0; }
    @media (max-width: 767px) {
      .garanty__col {
        width: 50%;
        margin-bottom: 30px; } }
    @media (max-width: 543px) {
      .garanty__col {
        width: 100%;
        margin-bottom: 50px; } }
  .garanty__body {
    padding-top: 62px;
    position: relative; }
    @media (max-width: 767px) {
      .garanty__body {
        padding: 0 40px; } }
    .garanty__body:before {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
      background-image: url("../img/icons/path.svg");
      top: 0;
      left: 12.5%;
      right: 12.5%;
      height: 60px; }
      @media (max-width: 767px) {
        .garanty__body:before {
          display: none; } }
  .garanty__dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: relative;
    margin: 0 auto; }
    .garanty__dot:before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -4px; }
  .garanty__desc {
    font-size: 17px;
    line-height: 1.64706;
    text-align: center;
    padding: 12px 15px 0; }
    @media (max-width: 1199px) {
      .garanty__desc {
        font-size: 14px;
        padding: 12px 5px 0; } }
    @media (max-width: 991px) {
      .garanty__desc {
        padding-top: 5px; } }
    @media (max-width: 767px) {
      .garanty__desc {
        font-size: 12px; } }
    .garanty__desc strong {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.4; }
      @media (max-width: 991px) {
        .garanty__desc strong {
          font-size: 16px; } }

/* ──────────────────────────────────────────────────────────────────
 * ── CLIENTS
 * ─────────────────────────────────────────────────────────────── */
.clients {
  padding: 115px 0 120px; }
  @media (max-width: 991px) {
    .clients {
      padding: 50px 0; } }
  .clients__top {
    margin-bottom: 90px; }
  @media (max-width: 767px) {
    .clients__title {
      margin-bottom: 30px; } }
  .clients__txt {
    color: #a3a4a5;
    font-size: 17px;
    line-height: 1.29412; }
    @media (max-width: 767px) {
      .clients__txt {
        font-size: 14px; } }
  .clients__sl .slide {
    height: 140px;
    outline: none; }
    @media (max-width: 767px) {
      .clients__sl .slide {
        height: 100px; } }
    .clients__sl .slide__in {
      display: flex;
      align-items: center;
      justify-content: center; }
      .clients__sl .slide__in img {
        max-height: 140px;
        max-width: 100%; }
        @media (max-width: 767px) {
          .clients__sl .slide__in img {
            height: 100px; } }
  .clients__sl .slick-arrow {
    position: absolute;
    top: -85px; }
    @media (max-width: 767px) {
      .clients__sl .slick-arrow {
        top: -50px; } }
    .clients__sl .slick-arrow.prev {
      left: 0; }
      @media (max-width: 767px) {
        .clients__sl .slick-arrow.prev {
          left: auto;
          right: 45px; } }
    .clients__sl .slick-arrow.next {
      left: 45px; }
      @media (max-width: 767px) {
        .clients__sl .slick-arrow.next {
          left: auto;
          right: 0; } }

/* ──────────────────────────────────────────────────────────────────
 * ── FAQ
 * ─────────────────────────────────────────────────────────────── */
.faq {
  padding: 105px 0 70px; }
  @media (max-width: 991px) {
    .faq {
      padding: 50px 0; } }
  .faq__title {
    margin-bottom: 80px; }
    @media (max-width: 991px) {
      .faq__title {
        margin-bottom: 40px; } }
  .faq__item {
    border-top: 1px solid #e5e7e7;
    padding: 20px 0 18px; }
  .faq__q {
    font-size: 17px;
    font-weight: 600; }
    @media (max-width: 767px) {
      .faq__q {
        margin-bottom: 15px;
        font-size: 16px; } }
  .faq__a {
    color: #757777;
    font-size: 15px;
    line-height: 1.46667; }
    @media (max-width: 767px) {
      .faq__a {
        font-size: 14px; } }

/* ──────────────────────────────────────────────────────────────────
 * ── QUESTIONS
 * ─────────────────────────────────────────────────────────────── */
.question {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/bg-question.jpg"); }
  @media (max-width: 767px) {
    .question {
      text-align: center; } }
  .question__in {
    padding: 65px 0 40px;
    color: #fff; }
    @media (max-width: 767px) {
      .question__in {
        padding: 50px 0 30px; } }
  .question__top {
    margin-bottom: 70px; }
    @media (max-width: 767px) {
      .question__top {
        margin-bottom: 35px; } }
  @media (max-width: 767px) {
    .question__title {
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .question__form {
      max-width: 60%;
      margin: 0 auto; } }
  @media (max-width: 543px) {
    .question__form {
      max-width: 100%; } }
  .question__form_footer {
    text-align: right; }
    @media (max-width: 1199px) {
      .question__form_footer {
        text-align: center; } }
    @media (max-width: 991px) {
      .question__form_footer {
        display: flex;
        flex-direction: column;
        align-items: center; } }
    .question__form_footer .sep {
      display: inline-block;
      margin: 0 12px; }
      @media (max-width: 991px) {
        .question__form_footer .sep {
          margin: 15px 0; } }
  .question__form_btn {
    min-width: 352px; }
    @media (max-width: 1199px) {
      .question__form_btn {
        min-width: auto; } }
    @media (max-width: 991px) {
      .question__form_btn {
        flex: none;
        width: 80%; } }
    @media (max-width: 543px) {
      .question__form_btn {
        width: 100%; } }

/* ──────────────────────────────────────────────────────────────────
 * ── CONTACT
 * ─────────────────────────────────────────────────────────────── */
.contact {
  padding: 77px 0 67px; }
  @media (max-width: 767px) {
    .contact {
      padding: 50px 0; } }
  .contact__box {
    height: 100%; }
    @media (max-width: 767px) {
      .contact__box {
        margin-bottom: 30px; } }
    @media (max-width: 767px) {
      .contact__box--map {
        height: 415px; } }
    .contact__box_in {
      height: 100%;
      background-color: #f6f7f9;
      padding: 67px 77px;
      position: relative; }
      @media (max-width: 991px) {
        .contact__box_in {
          padding: 40px 40px 0; } }
      @media (max-width: 767px) {
        .contact__box_in {
          padding: 20px 20px 0; } }
  .contact__title {
    margin-bottom: 80px; }
    @media (max-width: 991px) {
      .contact__title {
        margin-bottom: 40px; } }
  .contact__item {
    position: relative;
    padding-left: 77px;
    margin-bottom: 45px; }
    @media (max-width: 543px) {
      .contact__item {
        padding-left: 60px;
        margin-bottom: 25px; } }
  .contact__type {
    font-size: 20px;
    margin-bottom: 15px; }
    @media (max-width: 767px) {
      .contact__type {
        font-size: 18px; } }
  .contact__txt {
    color: #757777;
    font-size: 14px;
    line-height: 1.35714; }
    @media (max-width: 767px) {
      .contact__txt {
        font-size: 12px; } }
  .contact__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
    .contact__icon .ico {
      fill: #e96559;
      width: 39px;
      height: 41px; }

.map {
  background-color: #f6f7f9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
