/* ──────────────────────────────────────────────────────────────────
 * ── FONTS
 * ─────────────────────────────────────────────────────────────── */

@font-face {
  @include webfont('Montserrat','Montserrat Regular','Montserrat-Regular','hinted-Montserrat-Regular',normal,normal)
}
@font-face {
  @include webfont('Montserrat','Montserrat Medium','Montserrat-Medium','hinted-Montserrat-Medium',500,normal)
}
@font-face {
  @include webfont('Montserrat','Montserrat SemiBold','Montserrat-SemiBold','hinted-Montserrat-SemiBold',600,normal)
}
@font-face {
  @include webfont('Montserrat','Montserrat Bold','Montserrat-Bold','hinted-Montserrat-Bold',bold,normal)
}
@font-face {
  @include webfont('Montserrat','Montserrat ExtraBold','Montserrat-ExtraBold','hinted-Montserrat-ExtraBold',800,normal)
}
@font-face {
  @include webfont('Montserrat','Montserrat Black','Montserrat-Black','hinted-Montserrat-Black',900,normal)
}