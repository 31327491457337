.grid {
  box-sizing: border-box;
  padding: 0 15px;

  @include variant(container) {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
  }

  @include variant(no-gutters) {
    padding: 0;
  }
}